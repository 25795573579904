import {
  ADD_SUB,
  REMOVE_SUB,
  UPDATE_SUB_ALGO,
  ADD_MULTIPLE_SUBS,
  ADD_RFQ_SUB,
  ADD_RFQ_SUB_LIST,
  RFQ_DEAL_CLICKED,
} from './action_types';

export const addSubscription = (id, algo, widget) => (dispatch) =>
  dispatch({
    type: ADD_SUB,
    payload: { id, algo, widget },
  });

export const addMultipleSubscriptions = (payload) => (dispatch) =>
  dispatch({
    type: ADD_MULTIPLE_SUBS,
    payload,
  });

export const updateAlgo = (payload) => (dispatch) =>
  dispatch({
    type: UPDATE_SUB_ALGO,
    payload,
  });

export const stopSubscription = (id, algo, widget) => (dispatch) =>
  dispatch({
    type: REMOVE_SUB,
    payload: { id, algo, widget },
  });

export const addRfqSubscription = (payload) => ({
  type: ADD_RFQ_SUB,
  payload,
});

export const updateRfqSubscription = (payload) => ({
  type: ADD_RFQ_SUB,
  payload,
});

export const addRfqSubscriptionList = (payload) => ({
  type: ADD_RFQ_SUB_LIST,
  payload,
});

export const rfqDealClick = (payload) => ({
  type: RFQ_DEAL_CLICKED,
  payload,
});
