import { BOOK_UPDATE, RFQ_UPDATE, TOB_UPDATE } from './action_types';

export const reduxBookUpdate = (payload) => ({
  type: BOOK_UPDATE,
  payload,
});

export const reduxRfqUpdate = (payload) => ({
  type: RFQ_UPDATE,
  payload,
});

export const reduxTobUpdate = (payload) => ({
  type: TOB_UPDATE,
  payload,
});
