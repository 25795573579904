import { IJsonRowNode, IJsonTabSetNode, IJsonTabNode } from 'flexlayout-react';
import keyBy from 'lodash/keyBy';
import { Action, Dispatch } from '@reduxjs/toolkit';
import { SET_SETTINGS } from '~actions/action_types';
import getSettingByDomainAndKey from '~callers/getSettingByDomainAndKey';
import { SettingsType } from '~types/settings';

import { TraderJsonModal, WidgetType, WidgetIdentities } from './types';

import { widgetsNeedLoadUserSettings } from '../../../constants';

export const EdgeFXLayout: TraderJsonModal = {
  global: {
    splitterSize: 5,
    splitterExtra: 5,
    tabEnableFloat: true,
    tabSetEnableClose: true,
    tabSetMinWidth: 420,
    tabSetMinHeight: 250,
    tabEnableRename: false,
    borderMinSize: 0,
  },
  borders: [],
  layout: {
    type: 'row',
    id: '#03a783ba-d0a5-4a69-971e-075ec206bcc2',
    children: [
      {
        type: 'row',
        id: '#d6825558-de1c-4ea8-b572-8a1f0711c4cd',
        children: [
          {
            type: 'row',
            id: '#83b80e11-047b-44b7-b3d5-5be4ae6fdcd9',
            weight: 70,
            children: [
              {
                type: 'tabset',
                id: '#e12e81f0-6f28-4916-be4b-900fd3546fad',
                weight: 70,
                children: [
                  {
                    type: 'tab',
                    id: '#e48c931c-4ae8-43bf-8957-7fa6d6f7dfca',
                    name: 'Multicurrency Trading',
                    component: 'multiCurrency-widget',
                  },
                ],
                active: true,
              },
              {
                type: 'tabset',
                id: '#7822fa5c-670f-4e1a-b418-149e85b97be1',
                weight: 30,
                children: [
                  {
                    type: 'tab',
                    id: '#ad78b9b8-5c5e-41ac-bae1-b7dc9467d057',
                    name: 'Orders',
                    component: 'orders-widget',
                  },
                ],
              },
            ],
          },
          {
            type: 'row',
            id: '#3036a5e2-f5aa-4580-9223-aaf12d5eaa85',
            weight: 30,
            children: [
              {
                type: 'tabset',
                id: '#f927c556-8b8f-43da-9175-643eae80d153',
                weight: 55,
                children: [
                  {
                    type: 'tab',
                    id: '#3be83dd8-fc69-46ae-a497-413ea9bae791',
                    name: 'Blotter',
                    component: 'blotter-widget',
                  },
                ],
              },
              {
                type: 'tabset',
                id: '#cd4cd2d6-cb99-4aca-9155-e0804a79aa4f',
                weight: 45,
                children: [
                  {
                    type: 'tab',
                    id: '#1e4db999-fa78-4604-9d7f-da663aa7b25c',
                    name: 'Profit & Loss',
                    component: 'pnl-widget',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  openPopouts: [],
};

export const LatamFXLayout: TraderJsonModal = {
  global: {
    splitterSize: 5,
    splitterExtra: 5,
    tabEnableFloat: true,
    tabSetEnableClose: true,
    tabSetMinWidth: 400,
    tabSetMinHeight: 250,
    tabEnableRename: false,
  },
  borders: [],
  layout: {
    type: 'row',
    id: '#8fe81f0e-6f29-6769-be4d-900fd3546fbd',
    children: [
      {
        type: 'row',
        id: '#66e15eda-b6bc-4065-b60a-3589562e4659',
        children: [
          {
            type: 'row',
            id: '#14e26ec6-0fe8-4416-8ce3-cd80fe29fef4',
            weight: 70,
            children: [
              {
                type: 'tabset',
                id: '#1df171d3-d269-4431-b62c-9d8913a47f2f',
                weight: 33.33333333333333,
                children: [
                  {
                    type: 'tab',
                    id: '#0f28d92e-1f09-4301-a269-bafdee2218b0',
                    name: 'Tablero Informativo',
                    component: 'instruments-widget',
                  },
                ],
                active: true,
              },
              {
                type: 'tabset',
                id: '#640ca937-7fa5-4053-a556-65cd24225235',
                weight: 33.33333333333333,
                children: [
                  {
                    type: 'tab',
                    id: '#84c16434-7895-4a6c-b214-ed8af2e9af24',
                    name: 'Ejecución',
                    component: 'trading-widget',
                  },
                ],
                active: true,
              },
              {
                type: 'tabset',
                id: '#ada6f5c2-1ceb-470a-898f-711ef70cacf0',
                weight: 33.33333333333333,
                children: [
                  {
                    type: 'tab',
                    id: '#a370ef01-4e5d-4e08-913a-140f5c6dac01',
                    name: 'Órdenes',
                    component: 'orders-widget',
                  },
                ],
              },
            ],
          },
          {
            type: 'row',
            id: '#b0583580-4964-4602-a810-51ffb4bc7e41',
            weight: 30,
            children: [
              {
                type: 'tabset',
                id: '#3ca64376-223a-4941-a392-ac59a4aaee6d',
                weight: 100,
                children: [
                  {
                    type: 'tab',
                    id: '#a6541dbd-164c-4d52-a34d-27344ef8625b',
                    name: 'Blotter',
                    component: 'blotter-widget',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  openPopouts: [],
};

// design tab widget list
export const WidgetList: WidgetType[] = [
  {
    db: 'instruments_trading_widget',
    fl: 'instruments-trading-widget',
    lang: 'instrumentsTrading',
    enabled: true,
  },
  {
    db: 'instruments_widget',
    fl: 'instruments-widget',
    lang: 'instruments',
    enabled: true,
  },
  {
    db: 'orders_widget',
    fl: 'orders-widget',
    lang: 'orders',
    enabled: true,
  },
  {
    db: 'trading_widget',
    fl: 'trading-widget',
    lang: 'trading',
    enabled: true,
  },
  {
    db: 'multicurrency_widget',
    fl: 'multiCurrency-widget',
    lang: 'multiCurrency',
    enabled: true,
  },
  {
    db: 'rfs_widget',
    fl: 'rfs-widget',
    lang: 'rfs',
    enabled: true,
  },
  {
    db: 'rfq_widget',
    fl: 'rfq-widget',
    lang: 'rfq',
    enabled: true,
  },
  {
    db: 'rfq_blotter_widget',
    fl: 'rfq-blotter-widget',
    lang: 'rfq_blotter',
    enabled: true,
  },
  {
    db: 'pnl_widget',
    fl: 'pnl-widget',
    lang: 'pnl',
    enabled: true,
  },
  {
    db: 'pnl_widget2',
    fl: 'pnl-widget2',
    lang: 'pnl2',
    enabled: true,
  },
  {
    db: 'restingorders_widget',
    fl: 'restingOrders-widget',
    lang: 'restingOrders',
    enabled: true,
  },
  {
    db: 'blotter_widget',
    fl: 'blotter-widget',
    lang: 'blotter',
    enabled: true,
  },
  {
    db: 'pricing_widget',
    fl: 'pricing',
    lang: 'pricing',
    enabled: true,
  },
  {
    db: 'client_pricing_widget',
    fl: 'client_pricing-widget',
    lang: 'client_pricing',
    enabled: true,
  },
  {
    db: 'ladder_widget',
    fl: 'ladder-widget',
    lang: 'ladder',
    enabled: true,
  },
  {
    db: '',
    fl: 'trading-popout-widget',
    lang: 'tradingPopout_wiget',
    enabled: true,
  },
  {
    db: 'investing_chart_widget',
    fl: 'chart_widget',
    lang: 'chart',
    enabled: true,
  },
  {
    db: 'investing_economic_calendar_widget',
    fl: 'economic-calendar-widget',
    lang: 'economic_calendar',
    enabled: true,
  },
  {
    db: 'investing_rss_feed_widget',
    fl: 'rss-feed-widget',
    lang: 'rss_feed',
    enabled: true,
  },
  {
    db: 'trading_view_heatmap_widget',
    fl: 'heatmap-widget',
    lang: 'heatmap',
    enabled: true,
  },
];

export const mapWidgetFlToDb = (fl: string): string =>
  (keyBy(WidgetList, 'fl')?.[fl]?.db || '').replace('2', '').toLowerCase();

export const retrieveWidgets = (
  jsonNode: IJsonRowNode[] | IJsonTabSetNode[] | IJsonTabNode[],
  widgetIdentities: WidgetIdentities
): void => {
  jsonNode.forEach((item: IJsonRowNode | IJsonTabSetNode | IJsonTabNode) => {
    if (item.type === 'tab') {
      widgetIdentities[item.component] = widgetIdentities[item.component]
        ? widgetIdentities[item.component].concat([item.id])
        : [item.id];
    } else {
      const newItem = item as IJsonRowNode | IJsonTabSetNode;
      retrieveWidgets(newItem.children || [], widgetIdentities);
    }
  });
};

export const fetchWidgetUserSettings = async (
  domain: string,
  key: string,
  dispatch: Dispatch<Action>
): Promise<SettingsType | null> => {
  const data = await getSettingByDomainAndKey(encodeURIComponent(domain), key);

  if (data) {
    const settingsKey = domain as keyof typeof data;

    if (data?.[settingsKey]) {
      const settingsObj = data?.[settingsKey] || {};
      dispatch({
        type: SET_SETTINGS,
        payload: { settingsObj, domain },
      });

      return { [domain]: data?.[settingsKey] };
    }
    return null;
  }
  return null;
};

export const fetchSettings = async (
  layoutJosn: TraderJsonModal,
  dispatch: Dispatch<Action>
) => {
  const widgets: WidgetIdentities = {};
  retrieveWidgets(layoutJosn?.layout?.children || [], widgets);
  const widgetNames = Object.keys(widgets);
  const promiseAll: Promise<SettingsType | null>[] = [];

  widgetNames.forEach((key) => {
    const component = key;
    const domain = mapWidgetFlToDb(component);
    if (domain === 'instruments_trading_widget') {
      if (!widgetNames.includes('instruments_widget')) {
        promiseAll.push(
          fetchWidgetUserSettings('instruments_widget', '', dispatch)
        );
      }

      if (!widgetNames.includes('trading_widget')) {
        promiseAll.push(
          fetchWidgetUserSettings('trading_widget', '', dispatch)
        );
      }
    } else if (widgetsNeedLoadUserSettings.includes(domain)) {
      promiseAll.push(fetchWidgetUserSettings(domain, '', dispatch));
    }
  });

  await Promise.all(promiseAll);
};
