import {
  INCOMING_ORDER,
  CHANGE_FOLLOWER,
  FOLLOW_ORDER,
  SET_INITIAL_ORDERS,
  COMPLETED_ORDER,
  STP_UPDATE,
} from './action_types';

export const reduxIncomingOrder = (payload) => ({
  type: INCOMING_ORDER,
  payload,
});

export const reduxSetInitialOrders = (payload) => (dispatch) =>
  dispatch({
    type: SET_INITIAL_ORDERS,
    payload,
  });

export const reduxFollowClientOrders = (payload) => (dispatch) =>
  dispatch({
    type: CHANGE_FOLLOWER,
    payload,
  });

export const reduxFollowOrder = (payload) => ({
  type: FOLLOW_ORDER,
  payload,
});

/**
 * Sets to true if an order has been completed (amount got filled and finished)
 * if the order is canceled without any fill, it should not do anything
 * @param {boolean} payload - true or false
 */
export const reduxCompletedOrder = (payload) => ({
  type: COMPLETED_ORDER,
  payload,
});

export const reduxStpUpdate = (payload) => ({
  type: STP_UPDATE,
  payload,
});
