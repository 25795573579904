import {
  SET_PERMISSIONS,
  FOLLOW_CLIENTS,
  FOLLOW_THIS_CLIENT,
  SET_AUTHENTICATION_TOKEN,
  SHADOW_FOLLOW,
} from './action_types';

export const reduxSetPermissions = (payload) => ({
  type: SET_PERMISSIONS,
  payload,
});

export const reduxFollowClients = (payload) => ({
  type: FOLLOW_CLIENTS,
  payload,
});

export const reduxFollowThisClient = (payload) => (dispatch) =>
  dispatch({
    type: FOLLOW_THIS_CLIENT,
    payload,
  });

export const reduxSetToken = (payload) => (dispatch) =>
  dispatch({
    type: SET_AUTHENTICATION_TOKEN,
    payload,
  });

export const reduxShadowFollow = (payload) => (dispatch) =>
  dispatch({
    type: SHADOW_FOLLOW,
    payload,
  });
