import { CREDIT_CHECK_UPDATE, INSTRUMENTS_CREDIT_CHECK } from './action_types';

export const reduxCreditCheck = (payload) => ({
  type: CREDIT_CHECK_UPDATE,
  payload: payload?.data || payload,
});

export const reduxInstrumentsCreditCheck = (payload) => ({
  type: INSTRUMENTS_CREDIT_CHECK,
  payload,
});
